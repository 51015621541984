<template>
  <div class="changeUserinfo">
    <van-form @submit="onSubmit">
      <van-field
        v-model="userObj.u_nickname"
        name="u_nickname"
        label="昵称"
        placeholder="昵称"
      />
      <van-field
        v-model="userObj.u_signature"
        name="u_signature"
        label="个性签名"
        placeholder="个性签名"
      />
      <van-field
        name="u_sex"
        label="单选框"
      >
        <template #input>
          <van-radio-group
            v-model="userObj.u_sex"
            direction="horizontal"
          >
            <van-radio :name="0">女</van-radio>
            <van-radio :name="1">男</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="userObj.u_qq"
        name="u_qq"
        label="QQ"
        placeholder="QQ"
      />
      <van-field
        v-model="userObj.u_ali_account"
        name="u_ali_account"
        :disabled="copyUserInfo.u_real_name == ''&& copyUserInfo.u_ali_account!=''"
        label="支付宝账号"
        placeholder="支付宝账号"
        :error-message="ZFBErrMsg"
      />
      <p
        v-if="copyUserInfo.u_real_name == '' && copyUserInfo.u_ali_account!=''"
        class="ZFBtips"
      >如需修改支付宝请先完成实名</p>

      <van-field
        v-model="userObj.u_ali_realname"
        name="u_ali_realname"
        :disabled="copyUserInfo.u_real_name == '' && copyUserInfo.u_ali_account!=''"
        label="真实姓名"
        placeholder="真实姓名"
        :error-message="realnameMsg"
      />
      <p
        v-if="copyUserInfo.u_real_name == '' && copyUserInfo.u_ali_account!=''"
        class="ZFBtips"
      >如需修改支付宝请先完成实名</p>
      <div style="margin: 16px;">
        <van-button
          round
          block
          type="primary"
          native-type="submit"
        >
          提交
        </van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
import G_time from "../../tools/time";
export default {
  data() {
    return {
      ZFBErrMsg: "",
      realnameMsg: "",
      copyUserInfo: {},
      userObj: {
        u_add_time: "",
        u_ali_account: "",
        u_avatar: "",
        u_email: "",
        u_id: "",
        u_nickname: "",
        u_phone: "",
        u_regist_ip: "",
        u_regist_time: "",
        u_sex: "",
        u_signature: "",
        u_status: "",
        u_ali_realname: "",
        u_qq: "",
      },
    };
  },
  mounted() {
    this.getUserinfo();
    // this.userObj = this.$route.params;
  },

  methods: {
    getUserinfo() {
      this.$axios
        .get("/api/user/getInfo")
        .then((res) => {
          console.log(res);
          res.data.u_regist_time = G_time.ts2Date(res.data.u_regist_time);
          this.userObj = res.data;
          this.copyUserInfo = JSON.parse(JSON.stringify(this.userObj));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit(val) {
      // 清除支付宝中的空格
      val.u_ali_account = val.u_ali_account.replace(/\s*/g, "");
      if (
        /^(?:1[3-9]\d{9}|[a-zA-Z\d._-]*\@[a-zA-Z\d.-]{1,10}\.[a-zA-Z\d]{1,20})$/.test(
          val.u_ali_account
        )
      ) {
        this.ZFBErrMsg = "";
      } else {
        this.ZFBErrMsg = "支付宝账号格式错误";
        return false;
      }
      // 去除真实姓名中的空格
      val.u_ali_realname = val.u_ali_realname.replace(/\s*/g, "");

      if (
        /^[\u4e00-\u9fa5]{1,6}(·[\u4e00-\u9fa5]{1,6}){0,2}$/.test(
          val.u_ali_realname
        )
      ) {
        this.realnameMsg = "";
      } else {
        this.realnameMsg = "支付宝真实姓名必须全中文";
        return false;
      }
      this.$axios
        .post("/api/user/updateInfo", val)
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            this.$toast({
              type: "success",
              message: "修改成功",
            });
            this.$router.push({
              name: "personalCenter",
            });
          } else {
            this.$toast.fail(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang='scss'>
.changeUserinfo {
  .ZFBtips {
    color: red;
    margin-left: 100px;
  }
}
</style>